@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.header {
  border-top: 1px solid $color-gray-40;
}

.clickableRow {
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-10;
  }

  &:active {
    background-color: $color-gray-20;
  }

  &[data-state='selected'] {
    background-color: $color-blue-10;
  }

  &[data-state='disabled'] {
    td {
      opacity: 0.5;
    }
  }

  &:focus-visible {
    outline-offset: -2px;
    outline: 2px solid $color-blue-20;
  }
}

td,
th {
  &.cell {
    padding-left: $space-8;
    padding-right: $space-8;
  }
}
